<template>
  <v-container>
    <v-overlay :value="isLoadingInfo" opacity="0.85">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-dialog scrollable v-model="isPaybackDialog" max-width="500">
      <v-sheet>
        <v-card>
          <v-card-text>
            <div style="text-align: center" class="mb-4">
              <v-img width="50%" :src="service.image" class="mx-auto"></v-img>
            </div>
            <div>
              <p>
                Kindly make transfer of NGN {{ form.amount }} to
                {{ airtimeToCashInfo.a2cNumber }}
              </p>
              <p>
                Use <b>{{ airtimeToCashInfo.transferCode }}</b> as the transfer
                code
              </p>
              <p style="color: red">
                Note: Your account may get suspended if you didn't make a
                transfer <br />
                We don't accept VTU, refrain from such act.
              </p>
              <p>
                It takes approximately 5-20 minutes for your airtime to be
                verified and creadited to your wallet
              </p>
              <hr class="mb-4" />
              <h4>Terms of Use & Disclaimer</h4>
              <p>
                For more info about our
                <a href="https://snappyexchange.com/terms-and-condition"
                  >Terms of use</a
                >
                and
                <a href="https://snappyexchange.com/disclaimer">Disclaimer</a>,
                click on the links
              </p>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="red" @click="isPaybackDialog = false">Close</v-btn>
            <v-btn
              color="primary"
              @click="convertAirtimeTOCash"
              :loading="loadingTransaction"
              >Yes, I have made the transfer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-sheet>
    </v-dialog>
    <v-dialog scrollable v-model="isElectricityDetailDialog" max-width="500">
      <v-sheet>
        <v-card>
          <v-card-title>Verify Meter Info:</v-card-title>
          <v-card-text>
            <p>Address: {{ meterInfo.Address }}</p>
            <p>Customer Name: {{ meterInfo.Customer_Name }}</p>
            <p>Meter NUmber: {{ meterInfo.number }}</p>
            <p>Provider: {{ meterInfo.provider }}</p>
            <p>Type: {{ meterInfo.type }}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="red" @click="isElectricityDetailDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              @click="rechargeElectricity"
              :loading="loadingTransaction"
              >Proceed</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-sheet>
    </v-dialog>
    <v-dialog scrollable v-model="isElectricityCodeDialog" max-width="500">
      <v-sheet>
        <v-card>
          <v-card-title>Successful</v-card-title>
          <v-card-text> Token: {{ electricityToken }} </v-card-text>
          <v-card-actions>
            <v-spacer />

            <v-btn color="primary" @click="this.$router.push('/user/dashboard')"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-sheet>
    </v-dialog>
    <v-btn text color="accent" @click="$router.go(-1)" class="mb-2">
      <v-icon left> mdi-arrow-left-thick </v-icon>
      Back
    </v-btn>
    <h1 class="text-lg">{{ titleStart }} {{ service.provider }}</h1>
    <!-- <p class="text-subtitle-1 font-weight-bold">
      Got questions? Call or Whatsapp {{ siteData.phone }}
    </p> -->
    <v-row>
      <v-col cols="12" sm="4">
        <v-card
          flat
          color="white"
          rounded="lg"
          class="pa-5 animated__animated animate__fadeIn"
          width="100%"
        >
          <v-img width="100%" :src="service.image"></v-img>
        </v-card>
      </v-col>
      <v-col cols="12" sm="8">
        <v-card color="grey lighten-5" flat rounded="lg">
          <v-form ref="transactionForm" @submit.prevent="validate">
            <v-card class="" flat>
              <v-card-text>
                <b>
                  {{ airtimeToCashInfo.transferCode }} <br />
                  {{ airtimeToCashInfo.range }}
                </b></v-card-text
              >
            </v-card>
            <v-container>
              <v-text-field
                v-if="uiToShow.phoneNumber"
                :disabled="loadingTransaction"
                v-model="form.number"
                label="Phone Number"
                type="number"
                :rules="[uiToShow.phoneNumber && rules.required]"
                filled
                class="mt-5"
              ></v-text-field>
              <v-text-field
                v-if="uiToShow.number"
                :disabled="loadingTransaction"
                v-model="form.number"
                :rules="[uiToShow.number && rules.required]"
                :label="`${service.provider} Number`"
                type="number"
                filled
                class="mt-5"
              ></v-text-field>
              <v-text-field
                v-if="uiToShow.amount"
                v-model="form.amount"
                :rules="[uiToShow.amount && rules.required]"
                label="Amount"
                type="number"
                filled
                class="mt-5"
                :disabled="loadingTransaction"
                :hint="packageFeeOnAmount"
                persistent-hint
              ></v-text-field>
              <v-text-field
                v-if="uiToShow.senderNumber"
                v-model="form.senderNumber"
                :rules="[uiToShow.senderNumber && rules.required]"
                label="Sender Number"
                type="number"
                filled
                class="mt-5"
                :disabled="loadingTransaction"
                hint="Mobile number you are sending airtime from"
                persistent-hint
              ></v-text-field>
              <v-text-field
                v-if="uiToShow.receiverNumber"
                :rules="[uiToShow.receiverNumber && rules.required]"
                label="Receiver Number"
                type="number"
                filled
                class="mt-5"
                :value="airtimeToCashInfo.a2cNumber"
                disabled
              ></v-text-field>
              <v-select
                v-if="uiToShow.package"
                :label="packageTitle"
                :loading="packages.length === 0"
                v-model="form.packageId"
                :rules="[uiToShow.package && rules.required]"
                :items="packages"
                item-value="value"
                filled
                item-text="text"
                :hint="packageFeeOnPackage"
                @change="updateAmount"
                persistent-hint
              ></v-select>
              <div class="mt-5">
                <v-btn
                  color="primary"
                  width="100%"
                  large
                  @click="makeTransaction"
                  :loading="loadingTransaction"
                >
                  Confirm
                </v-btn>
              </div>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
import { numberWithCommas } from '../../../../utils/helpers'
import { mapState } from 'vuex'

import rules from '../../../../utils/rules'

import bill from '../../../../api/users/bill'

export default {
  data: () => ({
    numberWithCommas,
    titleStart: 'Topup',
    rules,
    form: {},
    service: {},
    packages: [],
    sendingOtp: false,
    isLoadingInfo: false,
    loadingTransaction: false,
    meterInfo: {},
    airtimeToCashInfo: {},
    electricityToken: '',
    isElectricityCodeDialog: false,
    packageFee: '',
    packageFeeOnPackage: null,
    packageFeeOnAmount: null,
    payback: 0,
    isPaybackDialog: false,
    rateToShow: 0,
    packageTitle: 'Select Package',
    isElectricityDetailDialog: false,
    uiToShow: {
      phoneNumber: false,
      amount: false,
      number: false,
      senderNumber: false,
      receiverNumber: false,
      package: false
    }
  }),
  async created() {
    this.service = this.$route.query
    if (this.$route.params.name === 'cable-tv') {
      this.titleStart = 'Pay'
      this.uiToShow.phoneNumber = false
      this.uiToShow.amount = false
      this.uiToShow.number = true
      this.uiToShow.package = true
      this.uiToShow.otp = true
      this.getCableProviders()
    }
    if (this.$route.params.name === 'airtime') {
      this.titleStart = 'Topup'
      this.uiToShow.phoneNumber = true
      this.uiToShow.amount = true
      this.uiToShow.number = false
      this.uiToShow.package = false
      this.packageFeeOnAmount = null
    }
    if (this.$route.params.name === 'convert-airtime') {
      this.titleStart = 'Convert'
      this.getAirtimeToCashInfo()
      this.uiToShow.phoneNumber = false
      this.uiToShow.senderNumber = true
      this.uiToShow.receiverNumber = true
      this.uiToShow.amount = true
      this.uiToShow.number = false
      this.uiToShow.package = false
      this.packageFeeOnAmount = null
    }
    if (this.$route.params.name === 'internet-data') {
      this.titleStart = 'Buy'
      this.uiToShow.phoneNumber = false
      this.uiToShow.amount = false
      this.uiToShow.number = true
      this.uiToShow.package = true
      this.packageFeeOnPackage = null
      this.getDataProviders()
    }
    if (this.$route.params.name === 'bettings-top-up') {
      this.titleStart = 'Topup'
      this.uiToShow.phoneNumber = false
      this.uiToShow.amount = true
      this.uiToShow.number = true
      this.uiToShow.package = false
    }
    if (this.$route.params.name === 'electricity') {
      this.titleStart = 'Pay'
      this.uiToShow.phoneNumber = false
      this.uiToShow.amount = true
      this.uiToShow.number = true
      this.uiToShow.package = true
      this.packages = [
        { text: 'Prepaid', value: 'prepaid' },
        { text: 'Postpaid', value: 'postpaid' }
      ]
      this.packageTitle = 'Select Meter Type'
    }
  },
  watch: {
    'form.amount': function () {
      if (this.$route.params.name === 'airtime') {
        const precentToRemove =
          Number(Number(Math.abs(this.siteData.airtimeRate)) / 100) *
          Number(this.form.amount)

        this.packageFeeOnAmount = `Total: NGN ${
          Number(this.siteData.airtime) < 0
            ? Number(this.form.amount) + Number(precentToRemove)
            : Number(this.form.amount) - precentToRemove
        }`
      }
      if (this.$route.params.name === 'electricity') {
        const fixedPrice = Number(this.siteData.electricityRate)
        this.packageFeeOnAmount = `Trx Charge: NGN ${fixedPrice}; Total: NGN ${
          fixedPrice + Number(this.form.amount)
        } `
      }
      if (this.$route.params.name === 'bettings-top-up') {
        this.packageFeeOnAmount = `Trx Charge: NGN ${
          this.siteData.bettingRate
        }; Total NGN ${
          Number(this.siteData.bettingRate) + Number(this.form.amount)
        }`
      }
      if (this.$route.params.name === 'convert-airtime') {
        const vm = this
        const fn = _.debounce(() => {
          vm.calculatePayback()
        }, 300)
        fn()
      }
    },
    'form.packageId': function () {
      if (this.$route.params.name === 'cable-tv') {
        const value = this.packages.find(
          (item) => item.id === this.form.packageId
        )

        this.packageFeeOnPackage = `Trx Change: NGN ${Number(
          this.siteData.cableRate
        )}; Total ${
          Number(value.amount.replace(/,/gi, '')) +
          Number(this.siteData.cableRate)
        }`
      }
      if (this.$route.params.name === 'internet-data') {
        const value = this.packages.find(
          (item) => item.id === this.form.packageId
        )

        console.log(value)

        this.packageFeeOnPackage = `Total NGN ${Number(value.amount)}`
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      siteData: (state) => state.user.siteSettings
    })
  },
  methods: {
    async rechargeAirtime() {
      this.loadingTransaction = true
      const res = await bill.billApi().airtime.buyAirtime({
        amount: this.form.amount,
        provider: this.service.provider,
        number: this.form.number
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loadingTransaction = false
        return
      }

      this.$store.dispatch('alert', {
        message: 'Recharge successful',
        status: true
      })

      this.loadingTransaction = false
      this.form.provider = ''
      this.form.amount = ''
      this.$router.push('/user/dashboard')
    },
    async rechargeData() {
      this.loadingTransaction = true
      const res = await bill.billApi().data.buyData({
        planId: this.form.packageId,
        provider: this.service.provider,
        number: this.form.number
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loadingTransaction = false
        return
      }

      this.$store.dispatch('alert', {
        message: 'Recharge successful',
        status: true
      })

      this.loadingTransaction = false
      this.form.provider = ''
      this.form.amount = ''
      this.$router.push('/user/dashboard')
    },
    async rechargeCable() {
      this.loadingTransaction = true
      const res = await bill.billApi().cable.topUp({
        customerId: this.form.number,
        provider: this.service.provider,
        planId: this.form.packageId,
        otp: this.form.otp
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loadingTransaction = false
        return
      }

      this.$store.dispatch('alert', {
        message: res.data.message || res.data.Data || 'Recharge successful',
        status: true
      })

      this.loadingTransaction = false
      this.form.number = ''
      this.form.providerId = ''
      this.airtime.otp = ''
      this.$router.push('/user/dashboard')
    },
    async rechargeBetting() {
      this.loadingTransaction = true
      const res = await bill.billApi().betting.topUp({
        amount: this.form.amount,
        number: this.form.number,
        provider: this.service.provider
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loadingTransaction = false
        return
      }

      this.$store.dispatch('alert', {
        message: 'Topup successful.',
        status: true
      })

      this.loadingTransaction = false
      this.form.number = ''
      this.form.providerId = ''
      this.$router.push('/user/dashboard')
    },
    async rechargeElectricity() {
      this.loadingTransaction = true

      // const isValidMeter = await this.validateMeterNumber()
      // if (!isValidMeter) {
      //   this.loadingTransaction = false
      //   return
      // }

      const res = await bill.billApi().electricity.topUp({
        type: this.form.packageId,
        amount: this.form.amount,
        number: this.form.number,
        provider: this.service.provider
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loadingTransaction = false
        return
      }

      this.$store.dispatch('alert', {
        message: res.data.message || 'Top-up Sucessfully, use the token shown',
        status: true
      })

      this.electricityToken = res.data.token
      this.isElectricityCodeDialog = true

      this.loadingTransaction = false
      this.form.number = ''
      this.form.providerId = ''
      // this.$router.push('/user/dashboard')
    },
    async convertAirtimeTOCash() {
      this.loadingTransaction = true

      const res = await bill.billApi().airtimeToCash.sendRequest({
        type: this.form.packageId,
        amount: this.form.amount,
        senderNumber: this.form.senderNumber,
        receiverNumber: this.airtimeToCashInfo.a2cNumber,
        provider: this.service.provider
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loadingTransaction = false
        return
      }

      this.$store.dispatch('alert', {
        message:
          res.data.message || res.data.Data || 'Request sent successfully',
        status: true
      })

      this.loadingTransaction = false
      this.form.number = ''
      this.form.providerId = ''
      this.$router.push('/user/dashboard')
    },
    async validateMeterNumber() {
      this.loadingTransaction = true
      const res = await bill.billApi().electricity.validateMeterNumber({
        type: this.form.packageId,
        number: this.form.number,
        provider: this.service.provider
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loadingTransaction = false
        return false
      }

      this.loadingTransaction = false
      this.isElectricityDetailDialog = true
      this.meterInfo = res.data.data
      return false
    },
    async getCableProviders() {
      const res = await bill
        .billApi()
        .cable.getProvidersPackages(this.service.provider)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
      }
      this.packages = res.data.data.map((item) => ({
        ...item,
        value: item.id,
        text: `${item.name} - (₦${numberWithCommas(item.amount)})`
      }))
    },
    async getDataProviders() {
      const res = await bill.billApi().data.getProvidersPackages({
        provider: this.service.provider
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
      }
      this.packages = res.data.data.map((item) => ({
        ...item,
        value: item.id,
        text: item.name
      }))
    },
    async getAirtimeToCashInfo() {
      this.isLoadingInfo = true
      const res = await bill.billApi().airtimeToCash.getTransferInstructions({
        provider: this.service.provider
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
      }

      this.isLoadingInfo = false
      this.airtimeToCashInfo = res.data.data[0]
      this.airtimeToCashInfo.range = `Minium of NGN ${this.airtimeToCashInfo.minAmount}, Maximum of NGN ${this.airtimeToCashInfo.maxAmount}`
    },
    async calculatePayback() {
      this.isLoadingInfo = true

      if (
        !(
          Number(this.form.amount) >=
            Number(this.airtimeToCashInfo.minAmount) &&
          Number(this.form.amount) <= Number(this.airtimeToCashInfo.maxAmount)
        )
      ) {
        this.$store.dispatch('alert', {
          message: `Amount  must be between ₦${numberWithCommas(
            this.airtimeToCashInfo.minAmount
          )} and ₦${numberWithCommas(this.airtimeToCashInfo.maxAmount)}`,
          error: true,
          status: true
        })
        this.isLoadingInfo = false
        return
      }

      const res = await bill.billApi().airtimeToCash.calculatePayback({
        provider: this.service.provider,
        amount: this.form.amount
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
      }

      this.isLoadingInfo = false
      this.payback = res.data.data.payback
      this.packageFeeOnAmount = `For NGN ${this.form.amount} airtime, you will get NGN ${this.payback}`
      // this.isPaybackDialog = true
    },
    makeTransaction() {
      if (!this.$refs.transactionForm.validate()) {
        return
      }

      if (this.$route.params.name === 'airtime') {
        this.rechargeAirtime()
      }
      if (this.$route.params.name === 'convert-airtime') {
        if (
          !(
            Number(this.form.amount) >=
              Number(this.airtimeToCashInfo.minAmount) &&
            Number(this.form.amount) <= Number(this.airtimeToCashInfo.maxAmount)
          )
        ) {
          this.$store.dispatch('alert', {
            message: `Amount  must be between ₦${numberWithCommas(
              this.airtimeToCashInfo.minAmount
            )} and ₦${numberWithCommas(this.airtimeToCashInfo.maxAmount)}`,
            error: true,
            status: true
          })
          this.isLoadingInfo = false
          return
        }
        this.isPaybackDialog = true
        // this.convertAirtimeTOCash()
      }
      if (this.$route.params.name === 'cable-tv') {
        this.rechargeCable()
      }
      if (this.$route.params.name === 'internet-data') {
        this.rechargeData()
      }
      if (this.$route.params.name === 'bettings-top-up') {
        this.rechargeBetting()
      }
      if (this.$route.params.name === 'electricity') {
        this.validateMeterNumber()
      }
    },
    updateAmount() {}
  }
}
</script>

<style></style>
